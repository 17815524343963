
import './App.css';
import Homepage from './components/Homepage';
import B from './components/B'
import Question3 from './components/Question3';


function App() {
  return (
    <div className="App">
      <Homepage/>
      <B/>
      <Question3/>
     
    </div>
  );
}

export default App;
